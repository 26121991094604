import React, { useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '242px',
    borderRadius: '0px 0px 12px 12px'
};

function GoogleMapsComponent({ address }) {

    const [center, setCenter] = useState({
        lat: 35.652832,
        lng: 139.839478
    })

    useEffect(() => {
        const loadMapData = async () => {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyDX0m7zrkQ6BKhIP8OXvkmAuL-HXvzDgNA`
            ).catch((err) => {
                console.log(err);
            });
            const data = await response.json();
            if (data.status === 'OK') {
                const { lat, lng } = data.results[0].geometry.location;
                setCenter({ lat, lng })
            }
        }

        loadMapData();

    }, [address])

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDX0m7zrkQ6BKhIP8OXvkmAuL-HXvzDgNA"
    })

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={16}
        >
        </GoogleMap>
    ) : <></>
}

export default React.memo(GoogleMapsComponent)