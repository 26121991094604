import React from 'react'
import { useNavigate } from 'react-router-dom';
import { encrypt } from '../util/crypto';
import { BOOK_STATUS } from '../constant/constant';

const BookItems = ({ books, url }) => {

    const navigate = useNavigate();

    return (
        <>
            {books &&
                Object.keys(books).map((key, index) => {
                    return (
                        <div key={index}>
                            <div className={index === 0 ? '' : 'mt-4'}>
                                <span className='ms-1 color-gray-main  me-2'>{key.split(' ')[0]}</span>
                                <span className='color-gray-main '>{key.split(' ')[1]}</span>
                            </div>
                            {books[key].map(item => {
                                return (
                                    <div
                                        className='input-box d-flex justify-content-between mt-3 cursor-pointer'
                                        onClick={() => navigate(url + encrypt(item._id))}
                                        key={item._id}
                                    >
                                        <div className={`${item.status === BOOK_STATUS.canceled ? 'color-gray-main' : 'color-gray-font'} py-1 text-ellipsis`}>{item.customerName}</div>
                                        <div className='d-flex'>
                                            {item.status === BOOK_STATUS.payed && <div className='check-indicator  me-2 mt-1'>
                                                <i className='fa fa-circle-check me-1'></i>
                                            </div>}
                                            {(item.status === BOOK_STATUS.verified || item.status === BOOK_STATUS.checked) && <div className='check-indicator  me-2 mt-1'>
                                                <i className='fa fa-check me-1'></i>
                                            </div>}
                                            {item.status === BOOK_STATUS.canceled && <div className='color-gray-main no-wrap mt-2 me-2'>キャンセル</div>}
                                            <div className={`${item.status === BOOK_STATUS.canceled || item.status === BOOK_STATUS.payed ? 'color-gray-main' : 'color-gray-font'} py-1 no-wrap`}>
                                                <i className='fa fa-yen me-1'></i>
                                                <span className='roboto text18'>{item.price}</span>
                                                <i className='ms-2 fa fa-angle-right'></i>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })
            }
        </>
    )
}

export default BookItems
