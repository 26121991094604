import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import bgSMS from '../../assets/bg_sms.svg'
import TopHeader from '../../component/TopHeader'
import { LocalStorageManager } from '../../util/localStorageManager'
import CustomButton from '../../component/CustomButton'

const RegisterSent = () => {
  const navigate = useNavigate()
  const [phoneNumber, setPhoneNumber] = useState('')

  const userRegisterVerify = () => {
    navigate('/register-verify')
  }

  useEffect(() => {
    const user = LocalStorageManager.getObjectItem(LocalStorageManager.tempUser)
    setPhoneNumber(user.phoneNumber)
  }, [])

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'SMSメッセージ送信完了'} value={'/register'} />
      <div className='grid-default'>
        <div className='text-center py-4'>
          <img src={bgSMS} width='65%' alt='SMS Background' />
        </div>
        <div className='text-center text24 color-gray-accent roboto-medium mt-3'>{phoneNumber.replace('+81', '0').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}</div>
        <div className='text-center color-gray-font text-medium my-2'>に認証コードを送信しました！</div>
        <div className='px-2 pt-4'>
          <CustomButton text={'次へ'} handleOnClick={() => userRegisterVerify()} />
        </div>
      </div>
    </div>
  )
}

export default RegisterSent
