import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { POLICY_TEXT } from '../constant/constant'
import CustomButton from '../component/CustomButton'
import InputTextarea from './inputs/InputTextarea'
import InputText from './inputs/InputText'
import InputNumber from './inputs/InputNumber'
import InputModalSelect from './inputs/InputModalSelect'
import PostCodeSearchModal from './modals/PostCodeSearchModal'

const StoreInputComponent = ({ store, setStore }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggle = () => setIsModalOpen(!isModalOpen)

  const [storeAddressModal, setStoreAddressModal] = useState(false)

  const toggleStoreAddressModal = () => setStoreAddressModal(!storeAddressModal)

  const determinePolicy = () => {
    const selectedOption = document.querySelector('input[name="store-policy"]:checked')
    if (selectedOption) {
      setStore({ ...store, policy: selectedOption.value })
    }
    toggle()
  }

  const handleSave = (param) => {
    setStore({ ...store, storeAddress: param })
    toggleStoreAddressModal()
  }

  return (
    <>
      <div className=''>
        <InputText
          label={'店舗名'}
          name={'store-name'}
          placeholder={'あなたの店舗名'}
          value={store.storeName}
          handleChange={(e) => setStore({ ...store, storeName: e.target.value })}
          checkable={true}
        />
        <InputNumber
          label={'店舗電話番号'}
          name={'store-number'}
          placeholder={'0120111111'}
          value={store.storeNumber}
          handleChange={(e) =>
            setStore({
              ...store,
              storeNumber: e.target.value.replace(/[^0-9-]+/g, ''),
            })
          }
          checkable={true}
        />
        <InputModalSelect label={'店舗住所'} value={store.storeAddress} handleClick={toggleStoreAddressModal} />
        <InputText
          label={'アクセス'}
          name={'store-access'}
          placeholder={'東京メトロ渋谷駅から徒歩5分'}
          value={store.storeAccess}
          handleChange={(e) => setStore({ ...store, storeAccess: e.target.value })}
          checkable={true}
        />
        <InputTextarea
          label={'営業時間'}
          name={'store-opening'}
          value={store.storeOpening}
          placeholder={'平日　10:00-18:00\n土日祝　9:00-19:00'}
          handleChange={(e) => setStore({ ...store, storeOpening: e.target.value })}
        />
        <InputTextarea
          label={'最終受付時間'}
          name={'store-general-last'}
          value={store.storeLast}
          placeholder={'平日　17:00\n土日祝　18:00'}
          handleChange={(e) => setStore({ ...store, storeLast: e.target.value })}
        />
        <InputText
          label={'定休日'}
          name={'store-closed'}
          value={store.storeClosed}
          placeholder={'第3木曜日'}
          handleChange={(e) => setStore({ ...store, storeClosed: e.target.value })}
          checkable={true}
        />
        <InputModalSelect label={'キャンセルポリシー'} value={store.policy} text={POLICY_TEXT[store.policy - 1]} handleClick={toggle} />
      </div>
      <Modal isOpen={isModalOpen} toggle={toggle} className='policy-modal' fade={false}>
        <ModalBody>
          <div className='color-gray-accent text16'>キャンセルポリシーについて</div>
          <div className=' mt-3 mb-4 text16 text-medium color-gray-font'>
            予約をキャンセルする場合に発生するキャンセル料です。<br></br>
            （支払い予約を利用されるお客様のみ）<br></br>
            以下の３つから設定することができます。
          </div>
          <div class='policy-options'>
            <div class='policy-option d-flex align-items-center cursor-pointer' onClick={() => setStore({ ...store, policy: '1' })}>
              <div class='policy-icon color-gray-main text20'>
                <i class={`fa-regular ${store.policy === '1' ? 'fa-circle-dot' : 'fa-circle'} me-3`}></i>
              </div>
              <div class={`policy-text ${store.policy === '1' ? 'color-gray-font' : 'color-gray text-medium'} pre-wrap text16`}>{POLICY_TEXT[0]}</div>
            </div>
            <div class='policy-option d-flex align-items-center cursor-pointer mt-4' onClick={() => setStore({ ...store, policy: '2' })}>
              <div class='policy-icon color-gray-main text20'>
                <i class={`fa-regular ${store.policy === '2' ? 'fa-circle-dot' : 'fa-circle'} me-3`}></i>
              </div>
              <div class={`policy-text ${store.policy === '2' ? 'color-gray-font' : 'color-gray text-medium'} pre-wrap text16`}>{POLICY_TEXT[1]}</div>
            </div>
            <div class='policy-option d-flex align-items-center cursor-pointer mt-4' onClick={() => setStore({ ...store, policy: '3' })}>
              <div class='policy-icon color-gray-main text20'>
                <i class={`fa-regular ${store.policy === '3' ? 'fa-circle-dot' : 'fa-circle'} me-3`}></i>
              </div>
              <div class={`policy-text ${store.policy === '3' ? 'color-gray-font' : 'color-gray text-medium'} pre-wrap text16`}>{POLICY_TEXT[2]}</div>
            </div>
          </div>
          <div className='mt-5 mb-3'>
            <CustomButton text={'決定'} handleOnClick={() => determinePolicy()} />
          </div>
        </ModalBody>
      </Modal>
      <PostCodeSearchModal isOpen={storeAddressModal} value={store.storeAddress} toggle={toggleStoreAddressModal} handleSave={handleSave} />
    </>
  )
}

export default StoreInputComponent
