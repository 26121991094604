import React, { useEffect, useState } from 'react'
import TopHeader from '../../component/TopHeader'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, FormGroup } from 'reactstrap'
import Select from 'react-select'
import axios from 'axios'
import { LocalStorageManager } from '../../util/localStorageManager'
import { BANK_ACCOUNT_TYPES } from '../../constant/constant'
import jsonData from '../../assets/bankJson.json'
import { padNumber } from '../../util/convert'
import CustomButton from '../../component/CustomButton'

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '1px solid #ccc' : 'none',
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
}

const NoOptionsMessage = (props) => {
  return <div className='color-gray-main p-2 text-center'>選択する項目がありません</div>
}

const TransferDestination = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [title, setTitle] = useState('振り込み先登録')
  const [isChange, setIschange] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [bank, setBank] = useState({
    bankName: '',
    bankBranchName: '',
    bankAccountType: '普通',
    bankAccountNumber: '',
    bankAccountHolderName: '',
  })

  useEffect(() => {
    if (location.pathname === '/change-transfer-destination') {
      setTitle('振り込み情報変更')
      setIschange(true)
      const store = LocalStorageManager.getObjectItem(LocalStorageManager.store)
      axios
        .post('/api/getTenant/', { tenantId: store.tenantId })
        .then((res) => {
          const tenant = res.data.tenant
          setBank({
            bankName: tenant.bank_code,
            bankBranchName: tenant.bank_branch_code,
            bankAccountType: tenant.bank_account_type,
            bankAccountNumber: tenant.bank_account_number,
            bankAccountHolderName: tenant.bank_account_holder_name,
          })
        })
        .catch((error) => {
          alert(error.response?.data?.message ?? 'エラー')
          console.log(error)
        })
    }
    // eslint-disable-next-line
  }, [location])

  const registerBank = () => {
    if (!Object.values(bank).every((value) => value)) {
      return
    }
    if (isSubmitting) return
    setIsSubmitting(true)

    const store = LocalStorageManager.getObjectItem(LocalStorageManager.store)
    const params = { ...bank, storeId: store._id }
    axios
      .post('/api/createTenant', params)
      .then((res) => {
        LocalStorageManager.setObjectItem(LocalStorageManager.store, res.data.store)
        navigate('/transfer-destination-complete')
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const changeBank = () => {
    if (!Object.values(bank).every((value) => value)) {
      return
    }
    if (isSubmitting) return
    setIsSubmitting(true)

    const store = LocalStorageManager.getObjectItem(LocalStorageManager.store)
    const params = { ...bank, tenantId: store.tenantId }
    axios
      .post('/api/updateTenant', params)
      .then(() => {
        alert('正常に更新されました。')
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={title} value={-1} />
      <div className='grid-default-resize pb-5'>
        {jsonData && (
          <Form className=''>
            <FormGroup className='input-box d-flex flex-column'>
              <div className='color-gray-accent ms-1 mb-1'>
                <span>金融機関名</span>
                {bank.bankName && <span className='ms-1 color-gray-main'>{bank.bankName}</span>}
              </div>
              <Select
                className='custom-select'
                name='bank-name'
                options={jsonData}
                isSearchable={true}
                placeholder='サロン銀行'
                value={jsonData.filter((item) => padNumber(item.value, 4) === bank.bankName)}
                onChange={(item) => setBank({ ...bank, bankName: padNumber(item.value, 4) })}
                styles={customStyles}
                components={{ NoOptionsMessage }}
              />
            </FormGroup>
            <FormGroup className='input-box d-flex flex-column'>
              <div className='color-gray-accent ms-1 mb-1'>
                <span>支店名</span>
                {bank.bankBranchName && <span className='ms-1 color-gray-main'>{bank.bankBranchName}</span>}
              </div>
              <Select
                className='custom-select'
                name='bank-branch-name'
                options={bank.bankName ? jsonData.filter((item) => padNumber(item.value, 4) === bank.bankName)[0]?.branches : []}
                isSearchable={true}
                placeholder='駅前店'
                value={jsonData.filter((item) => padNumber(item.value, 4) === bank.bankName)[0]?.branches.filter((item) => padNumber(item.value, 3) === bank.bankBranchName)}
                onChange={(item) => setBank({ ...bank, bankBranchName: padNumber(item.value, 3) })}
                styles={customStyles}
                components={{ NoOptionsMessage }}
              />
            </FormGroup>
            <FormGroup className='input-box d-flex flex-column'>
              <div className='color-gray-accent ms-1 mb-1'>口座種別</div>
              <Select
                className='custom-select'
                name='bank-account-type'
                options={BANK_ACCOUNT_TYPES}
                placeholder='普通'
                value={BANK_ACCOUNT_TYPES.filter((item) => item.value === bank.bankAccountType)}
                onChange={(item) => setBank({ ...bank, bankAccountType: item.value })}
                styles={customStyles}
              />
            </FormGroup>
            <FormGroup className='input-box d-flex flex-column'>
              <div className='color-gray-accent ms-1 mb-1'>口座番号</div>
              <input
                inputMode='numeric'
                className='custom-input roboto'
                name='bank-account-number'
                placeholder='01234567'
                value={bank.bankAccountNumber}
                onChange={(e) => setBank({ ...bank, bankAccountNumber: e.target.value.replace(/[^0-9-]+/g, '') })}
              />
            </FormGroup>
            <FormGroup className='input-box d-flex flex-column'>
              <div className='color-gray-accent ms-1 mb-1'>口座名義</div>
              <input
                className='custom-input'
                name='bank-account-holder-name'
                placeholder='ヤマモトサユリ'
                value={bank.bankAccountHolderName}
                onChange={(e) => setBank({ ...bank, bankAccountHolderName: e.target.value })}
              />
            </FormGroup>
          </Form>
        )}
        <div className='my-4'>
          {isChange ? (
            <CustomButton text={'更新'} handleOnClick={() => changeBank()} disabled={!Object.values(bank).every((value) => value)} />
          ) : (
            <CustomButton text={'登録'} handleOnClick={() => registerBank()} disabled={!Object.values(bank).every((value) => value)} />
          )}
        </div>
      </div>
    </div>
  )
}

export default TransferDestination
