import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LocalStorageManager } from '../../util/localStorageManager'
import { deconvertNumber } from '../../util/convert'
import CustomButton from '../../component/CustomButton'
import RegistrantInputComponent from '../../component/RegistrantInputComponent'
import StoreHeader from '../../component/StoreHeader'

const StoreRegisterSecond = () => {
  const navigate = useNavigate()
  const [store, setStore] = useState({
    registrantName: '',
    registrantNumber: '',
    registrantAddress: '',
  })

  useEffect(() => {
    const exist = LocalStorageManager.existItem(LocalStorageManager.store)
    if (exist) {
      alert('すでに店舗を登録済みです。')
      navigate('/home')
    }
    const storeData = LocalStorageManager.getObjectItem(LocalStorageManager.tempStore)
    const user = LocalStorageManager.getObjectItem(LocalStorageManager.user)
    setStore({
      ...store,
      registrantName: storeData.registrantName,
      registrantNumber: storeData.registrantNumber ?? deconvertNumber(user.phoneNumber),
      registrantAddress: storeData.registrantAddress,
    })
    // eslint-disable-next-line
  }, [])

  const stepNext = () => {
    if (!Object.values(store).every((value) => value)) {
      return
    }
    const prevStoreData = LocalStorageManager.getObjectItem(LocalStorageManager.tempStore)
    LocalStorageManager.setObjectItem(LocalStorageManager.tempStore, { ...prevStoreData, ...store })
    navigate('/store-register-third')
  }

  return (
    <div className='default-container bg-default m-auto'>
      <StoreHeader title={'あなたについて教えてください'} value={'/store-register-first'} page={2} />
      <div className='grid-default-header pt-0'>
        <RegistrantInputComponent store={store} setStore={setStore} />
        <div className='mt-5'></div>
        <CustomButton text={'次へ'} handleOnClick={() => stepNext()} disabled={!Object.values(store).every((value) => value)} />
      </div>
    </div>
  )
}

export default StoreRegisterSecond
