import { TextareaAutosize } from '@mui/material'

const InputBook = ({ icon, name, placeholder, value, handleChange, checkable, isNumber, isTextArea }) => {
  return (
    <div className='input-box d-flex flex-column mb-3'>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='color-gray-main me-2 text16' style={{ minWidth: '18px' }}>
          <i className={icon}></i>
        </span>
        {isTextArea ? (
          <TextareaAutosize className='custom-input textarea me-2' name={name} placeholder={placeholder} value={value} onChange={handleChange} />
        ) : (
          <input
            inputMode={isNumber ? 'numeric' : 'text'}
            className={`custom-input me-2 ${isNumber ? 'roboto text18' : 'text16'}`}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
          />
        )}
        {checkable && value && <i className='fa fa-circle-check check-indicator' />}
      </div>
    </div>
  )
}

export default InputBook
