import React from 'react'
import { useNavigate } from 'react-router-dom'
import TopHeader from '../../component/TopHeader'

const RegisterGuide = () => {
  const navigate = useNavigate()

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'SMSメッセージが届かない'} value={'/register-verify'} />
      <div className='grid-default'>
        <div className='text-left text-medium  color-gray-font py-4'>電話番号にショートメッセージ（SMS）が届かない場合、下記の内容を確認してください。</div>
        <div className='input-box border-green'>
          <div className='color-gray-main-check-point mb-1'>
            <i className='fa fa-check me-2'></i>
            <div>Check Point</div>
          </div>
          <div className='color-gray-font  text-medium'>ご登録の際にご入力いただいた電話番号に誤りがないかご確認ください。</div>
        </div>
        <div className='input-box mt-3 border-green'>
          <div className='color-gray-main-check-point mb-1'>
            <i className='fa fa-check me-2'></i>
            <div>Check Point</div>
          </div>
          <div className='color-gray-font  text-medium'>SMSに対応していない携帯電話の電話番号は利用できません。SMSを利用できるスマートフォンや携帯電話でお試しください。</div>
        </div>
        <div className='text-center color-gray-accent mt-4 py-4'>
          <span className='cursor-pointer' onClick={() => navigate('/register')}>
            もう一度初めから登録する
          </span>
        </div>
      </div>
    </div>
  )
}

export default RegisterGuide
