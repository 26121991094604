import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { convertDetailDateTime, replaceText } from '../../util/convert'
import { decrypt } from '../../util/crypto'
import { Modal, ModalBody, Spinner } from 'reactstrap'
import { BOOK_STATUS, POLICY_TEXT } from '../../constant/constant'
import Contact from './Contact'
import GreenHeader from '../../component/GreenHeader'
import CustomButton from '../../component/CustomButton'
import { getImage, getHeight } from '../../util/brand'

const ClientBook = () => {
  const navigate = useNavigate()
  const param = useParams()
  const [detail, setDetail] = useState(null)
  const [store, setStore] = useState(null)
  const [brands, setBrands] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isContact, setIsContact] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggle = () => setIsModalOpen(!isModalOpen)

  const [openTutor, setopenTutor] = useState(false)
  const toggleTutor = () => setopenTutor(!openTutor)

  useEffect(() => {
    if (!param.id) {
      return
    }
    axios
      .get(`/api/book/${decrypt(param.id)}`)
      .then((res) => {
        if (res.data && res.data.detail) {
          setDetail(res.data.detail)
          setStore(res.data.store)
          setBrands(res.data.brands ?? [])
          if (res.data.detail.status === BOOK_STATUS.booked && res.data.detail.paymentMethod !== 3 && res.data.detail.paymentMethod !== null && res.data.store.deleted_dt === null) {
            toggleTutor()
          }
        }
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })

    const script = document.createElement('script')
    script.src = 'https://checkout.pay.jp/'
    script.className = 'payjp-button'
    script.setAttribute('data-key', process.env.REACT_APP_PAYJP_PUBLICKEY)
    script.setAttribute('data-submit-text', 'トークンを作成する')
    script.setAttribute('data-partial', 'true')
    script.async = true
    document.querySelector('#checkout').appendChild(script)
    // eslint-disable-next-line
  }, [param])

  const handleBookDelete = () => {
    axios
      .delete(`/api/book/${decrypt(param.id)}`)
      .then((res) => {
        setDetail(res.data.detail)
        setStore(res.data.store)
        toggle()
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })
  }

  const handleUpfront = () => {
    if (isSubmitting) return
    const button = document.querySelector('#payjp_checkout_box').children[0]
    button.click()
    const handleInputChange = (mutations) => {
      if (mutations[0].target.value) {
        setIsSubmitting(true)
        axios
          .post(`/api/tenantPayment`, {
            id: decrypt(param.id),
            token: mutations[0].target.value,
          })
          .then((res) => {
            navigate('/book-complete/' + param.id)
          })
          .catch((error) => {
            alert(error.response?.data?.message ?? 'エラー')
            console.log(error)
          })
          .finally(() => {
            setIsSubmitting(false)
          })
      }
    }

    const observer = new MutationObserver(handleInputChange)
    observer.observe(document.querySelector('input[name="payjp-token"]'), {
      attributes: true,
      attributeFilter: ['value'],
    })
  }

  const checkShowPolicy = () => {
    const bookDate = new Date(detail.dateTime)
    const currentDate = new Date()

    // Compare only the date, ignoring the time
    const bookDateOnly = new Date(bookDate.getFullYear(), bookDate.getMonth(), bookDate.getDate())
    const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())

    if (bookDateOnly - currentDateOnly > 24 * 60 * 60 * 1000) {
      // 1 day in milliseconds
      return false
    }

    return true
  }

  return (
    <>
      {isContact ? (
        <Contact store={store} isContact={isContact} />
      ) : (
        <div className='default-container bg-default m-auto'>
          <GreenHeader pageTitle={'予約内容'} isHide={true} />
          {detail && detail.status === BOOK_STATUS.canceled && <div className='deleted-bar text-white '>この予約はキャンセル済みです。</div>}
          {store && store.deleted_dt !== null && <div className='deleted-bar text-white '>この予約は店舗の都合によりキャンセルされました。</div>}
          <div className='grid-default-resize pt-2'>
            <div id='checkout' className='d-none'></div>
            {isSubmitting ? (
              <div className='pt-5 text-center color-gray-main text20'>
                <Spinner />
                <div>処理中</div>
              </div>
            ) : detail ? (
              <>
                <div className='d-flex justify-content-between pt-2'>
                  <div className='color-gray-main  ms-1'>
                    {detail.status === 'canceled' || store.deleted_dt !== null ? (
                      <>
                        <span className='roboto me-2'>{convertDetailDateTime(detail.updated_dt)}</span>
                        <span>に予約キャンセル</span>
                      </>
                    ) : (
                      <>
                        <span className='roboto me-2'>{convertDetailDateTime(detail.created_dt)}</span>
                        <span>時点での予約内容</span>
                      </>
                    )}
                  </div>
                </div>
                {detail.status === BOOK_STATUS.booked ? (
                  <>
                    {detail.price && (
                      <div className='input-box-green-sm mt-3' onClick={() => handleUpfront()}>
                        <div className='color-gray-main mb-1'>お支払い予定額</div>
                        <div className='d-flex justify-content-between align-items-center mb-1'>
                          <div>
                            <i className='fa fa-yen me-1 color-gray-main'></i>
                            <span className='color-gray-main text18 roboto'>{replaceText(detail.price)}</span>
                          </div>
                          {detail.paymentMethod !== 3 && detail.paymentMethod !== null && store.deleted_dt === null && (
                            <div className='color-gray-main cursor-pointer'>
                              <span>{detail.paymentMethod === 1 ? '事前決済する' : '予約を確定する'}</span>
                              <i className='fa fa-angle-right ms-2'></i>
                            </div>
                          )}
                        </div>
                        <div className='color-gray-main'>以下のカードがご利用いただけます</div>
                        <div className='d-flex align-items-center'>
                          {brands.map((brand) => {
                            return <img src={getImage(brand.brand)} className='me-2' alt='brand' key={brand} style={{ height: getHeight(brand.brand) }} />
                          })}
                        </div>
                      </div>
                    )}
                  </>
                ) : detail.status === BOOK_STATUS.verified || detail.status === BOOK_STATUS.checked ? (
                  <div className='input-box mt-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='color-gray-main'>{detail.paymentMethod === 1 ? '事前決済済み' : '予約確定'}</div>
                      <div className='color-gray-main '>
                        <i className='fa fa-yen me-1 text18'></i>
                        <span className='roboto text24 me-2'>{detail.price}</span>
                        <span className='check-indicator'>
                          <i className='fa fa-circle-check me-1'></i>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : detail.status === BOOK_STATUS.payed ? (
                  <div className='input-box mt-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='color-gray-main'>決済済み</div>
                      <div className='color-gray-main '>
                        <i className='fa fa-yen me-1 text18'></i>
                        <span className='roboto text24 me-2'>{detail.price}</span>
                        <span className='check-indicator'>
                          <i className='fa fa-circle-check me-1'></i>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='input-box mt-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='color-gray-main'>キャンセル済み</div>
                      {detail.price && (
                        <div className='color-gray-main '>
                          <i className='fa fa-yen me-1 text18'></i>
                          <span className='roboto text24 me-2'>{detail.price}</span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {detail.product && (
                  <div className='input-box mt-3'>
                    <div className='color-gray-main mb-1'>メッセージ</div>
                    <div className='color-gray-font pre-wrap'>{detail.product}</div>
                  </div>
                )}
                <div className='input-box mt-3'>
                  <div className='color-gray-main mb-1'>来店予定日</div>
                  <div className='color-gray-font roboto text18 me-1'>{convertDetailDateTime(detail.dateTime)}</div>
                </div>
                <div className='input-box-green-sm mt-3 cursor-pointer' onClick={() => setIsContact(true)}>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='color-gray-font'>{store ? store.storeName : ''}</div>
                    <div className='color-gray-main'>
                      <span>店舗情報</span>
                      <i className='fa fa-angle-right ms-2 color-gray-font'></i>
                    </div>
                  </div>
                </div>
                {detail.status !== BOOK_STATUS.payed && detail.status !== BOOK_STATUS.canceled && store.deleted_dt === null && (
                  <div className='text-center color-gray-main mt-4 pb-4'>
                    <span className='cursor-pointer' onClick={toggle}>
                      予約をキャンセルする
                    </span>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          {store && (
            <Modal isOpen={isModalOpen} toggle={toggle} className='cancel-modal' centered>
              <ModalBody>
                <div className='d-flex justify-content-end'>
                  <div className='btn-close cursor-pointer' onClick={toggle}></div>
                </div>
                <div className='text-center color-gray-main text20 mb-3 pt-2'>予約をキャンセルします。</div>
                <div className='d-flex flex-column align-items-center'>
                  {checkShowPolicy() && (
                    <div className='input-box-gray mb-5'>
                      <div className='text-center color-gray-font  mb-2'>キャンセルポリシー</div>
                      <div className='color-gray-font text-start pre-wrap mb-2'>{POLICY_TEXT[parseInt(store.policy) - 1]}</div>
                      <div className='color-gray-font text-start no-wrap'>の料金をお支払いいただきます。</div>
                      <div className='color-gray-font text-start'>よろしいですか？</div>
                    </div>
                  )}
                  <CustomButton text='予約をキャンセルする' handleOnClick={() => handleBookDelete()} />
                  <div className='text-center color-gray  mt-3'>
                    <span className='cursor-pointer' onClick={toggle}>
                      閉じる
                    </span>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          )}
          <Modal isOpen={openTutor} toggle={toggleTutor} className='alert-modal logout-modal' centered size='sm' fade={false}>
            <ModalBody>
              <div className='d-flex flex-column align-items-center m-3'>
                <div className='color-gray-font mb-5 '>予約を確定させてください。</div>
                <div className='color-gray-main '>
                  <span onClick={toggleTutor} className='p-2 cursor-pointer'>
                    閉じる
                  </span>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  )
}

export default ClientBook
