import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import TopHeader from '../../component/TopHeader';
import { convertDetailDateTime, replaceText } from '../../util/convert';
import axios from 'axios';
import { decrypt } from '../../util/crypto'
import { METHOD_TEXT } from '../../constant/constant';
import CustomButton from '../../component/CustomButton';

const Payment = () => {

    const navigate = useNavigate();
    const param = useParams();
    const [pageTitle, setPageTitle] = useState('決済');
    const [detail, setDetail] = useState(null);
    const [isPayed, setIsPayed] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (!param.id) {
            return;
        }
        axios.get(`/api/books/${decrypt(param.id)}`)
            .then((res) => {
                if (res.data && res.data.detail) {
                    setDetail(res.data.detail)
                }
            }).catch((error) => {
                alert(error.response?.data?.message ?? "エラー");
                console.log(error)
            })
    }, [param])

    const payByCard = () => {
        if (isSubmitting) return;
        setIsSubmitting(true);

        axios.post('/api/books/payment', { id: decrypt(param.id), payment: 'card' })
            .then((res) => {
                setIsPayed(1);
                setPageTitle('決済完了')
            }).catch((error) => {
                alert(error.response?.data?.message ?? "エラー");
                console.log(error)
            }).finally(() => {
                setIsSubmitting(false)
            })
    }

    const payByCash = () => {
        if (isSubmitting) return;
        setIsSubmitting(true);

        axios.post('/api/books/payment', { id: decrypt(param.id), payment: 'cash' })
            .then((res) => {
                setIsPayed(2);
                setPageTitle('決済完了')
            }).catch((error) => {
                alert(error.response?.data?.message ?? "エラー");
                console.log(error)
            }).finally(() => {
                setIsSubmitting(false)
            })
    }

    return (
        <div className='default-container bg-default m-auto'>
            <TopHeader pageTitle={pageTitle} value={isPayed ? '/home' : -1} />
            <div className='grid-default-resize pt-2'>
                {detail && isPayed === 0 &&
                    <>
                        <div className='input-box mt-1'>
                            <span className='color-gray-accent text24 me-3'><i className='fa fa-yen'></i></span>
                            <span className='color-gray-main-28 roboto'>{replaceText(detail.price)}</span>
                        </div>
                        <div className='input-box mt-3'>
                            <span className='color-gray-font text20 me-1'>{detail.customerName}</span>
                            <span className='color-gray-font text-16'>さま</span>
                        </div>
                        <div className='input-box mt-3'>
                            <div className='color-gray-font'>{METHOD_TEXT[detail.paymentMethod - 1]}</div>
                        </div>
                        <div className='mt-5'></div>
                        <CustomButton text={'クレジットカードで決済する'} handleOnClick={() => payByCard()} />
                        <div className='text-center color-gray-main mt-3 pb-4'>
                            <span className='cursor-pointer' onClick={() => payByCash()}>現金で決済する</span>
                        </div>
                    </>
                }
                {detail && isPayed !== 0 &&
                    <div className='d-flex flex-column align-items-center mt-1'>
                        <div className='input-box-green' >
                            <div className='text-center'>
                                <span className='color-gray-font  me-1'>{detail.customerName}</span>
                                <span className='color-gray-font'>さま</span>
                            </div>
                            <div className='text-center'>
                                <span className='color-gray-main'>{convertDetailDateTime(detail.dateTime)}</span>
                            </div>
                            <div className='text-center mt-3'>
                                <span><i className='fa fa-circle-check check-indicator-2 me-4'></i></span>
                                <span className='color-gray-accent text24 me-2'><i className='fa fa-yen'></i></span>
                                <span className='color-gray-main-28 roboto'>{replaceText(detail.price)}</span>
                            </div>
                            <div className='text-center color-gray-main mt-3'>
                                <div className='text-frame'>{isPayed === 1 ? 'クレジットカード' : '現金決済'}</div>
                            </div>
                        </div>
                        <div className='mt-5'></div>
                        <CustomButton text={'TOPへ戻る'} handleOnClick={() => navigate('/home')} />
                    </div>
                }
            </div>
        </div >
    )
}

export default Payment