import { METHOD_TEXT } from "../constant/constant";
import { deconvertNumber, convertDetailDateTime } from "./convert";

export const handleCopy = (detail) => {
    const customerName = "顧客名 : " + detail.customerName + '\n';
    const customerNumber = "電話番号 : " + deconvertNumber(detail.customerNumber) + '\n';
    const customers = "予約人数 : " + detail.customers + '\n';
    const price = "金額 : ¥" + detail.price + '\n';
    const product = "メッセージ : " + deconvertNumber(detail.product) + '\n';
    const dateTime = "来店予定日 : " + convertDetailDateTime(detail.dateTime) + '\n';
    const paymentMethod = "決済方法 : " + METHOD_TEXT[detail.paymentMethod - 1] + '\n';
    const data = customerName + customerNumber + customers + price + product + dateTime + paymentMethod;

    navigator.clipboard.writeText(data);
    alert('クリップボードにコピーしました\n\n' + data);
}