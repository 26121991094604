import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import TopHeader from '../../component/TopHeader';
import { convertDetailDateTime, replaceText } from '../../util/convert';
import axios from 'axios';
import { decrypt } from '../../util/crypto'
import CustomButton from '../../component/CustomButton';

const ClientBookComplete = () => {

    const navigate = useNavigate();
    const param = useParams();
    const [detail, setDetail] = useState(null);

    useEffect(() => {
        if (!param.id) {
            return;
        }
        axios.get(`/api/book/${decrypt(param.id)}`)
            .then((res) => {
                if (res.data && res.data.detail) {
                    setDetail(res.data.detail)
                }
            }).catch((error) => {
                alert(error.response?.data?.message ?? "エラー");
                console.log(error)
            })
    }, [param])

    return (
        <div className='default-container bg-default m-auto'>
            {detail && <TopHeader pageTitle={detail.paymentMethod === 1 ? '事前決済完了' : '予約確定'} value={-1} />}
            {detail && <div className='grid-default-resize pt-2'>
                <div className='d-flex flex-column align-items-center mt-1'>
                    <div className='input-box-green' >
                        <div className='text-center'>
                            <span className='color-gray-main'>{convertDetailDateTime(detail.updated_dt)}</span>
                        </div>
                        <div className='text-center mt-3'>
                            <span><i className='fa fa-circle-check check-indicator-2 me-4'></i></span>
                            <span className='color-gray-accent text24 me-2'><i className='fa fa-yen'></i></span>
                            <span className='color-gray-main-28 roboto'>{replaceText(detail.price)}</span>
                        </div>
                        <div className='text-center color-gray-main mt-3'>
                            <div className='text-frame'>クレジットカード</div>
                        </div>
                    </div>
                    <div className='input-box mt-3 mb-5'>
                        <div className='color-gray-main mb-1'>来店予定日</div>
                        <div className='color-gray-font roboto text18 me-1'>{convertDetailDateTime(detail.dateTime)}</div>
                    </div>
                    <CustomButton text='予約内容に戻る' handleOnClick={() => navigate('/book/' + param.id)} />
                </div>
            </div>}
        </div >
    )
}

export default ClientBookComplete