import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import BookingHeader from '../../component/BookingHeader'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import axios from 'axios'
import { deconvertNumber, convertPhoneNumber, convertPickerDateTime, replaceText } from '../../util/convert'
import { decrypt, encrypt } from '../../util/crypto'
import { METHOD_TEXT } from '../../constant/constant'
import { LocalStorageManager } from '../../util/localStorageManager'
import { handleCopy } from '../../util/copy'
import CustomButton from '../../component/CustomButton'
import InputBook from '../../component/inputs/InputBook'
import InputModalSelect from '../../component/inputs/InputModalSelect'
import CloneButton from '../../component/CloneButton'

const AcceptBooking = () => {
  const navigate = useNavigate()
  const param = useParams()
  const storeRef = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isTutorial, setIsTutorial] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [preview, setPreview] = useState(false)
  const [pageTitle, setPageTitle] = useState('予約受付')
  const [detail, setDetail] = useState(0)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [book, setBook] = useState({
    price: '',
    product: '',
    dateTime: '',
    customerNumber: '',
    customers: '',
    customerName: '',
    paymentMethod: null,
  })

  const toggle = () => setIsModalOpen(!isModalOpen)
  const toggleTutorial = () => setIsTutorial(!isTutorial)

  useEffect(() => {
    storeRef.current = LocalStorageManager.getObjectItem(LocalStorageManager.store)
    if (param.id) {
      axios
        .get(`/api/history/${decrypt(param.id)}`)
        .then((res) => {
          if (res.data && res.data.detail) {
            const { price, product, dateTime, customerNumber, customers, customerName, paymentMethod } = res.data.detail
            setBook({
              price,
              product,
              dateTime,
              customerNumber: deconvertNumber(customerNumber),
              customers,
              customerName,
              paymentMethod,
            })
          }
        })
        .catch((error) => {
          alert(error.response?.data?.message ?? 'エラー')
          console.log(error)
        })
    } else {
      axios
        .post('/api/books', { pageNumber: 1, userId: storeRef.current.userId })
        .then((res) => {
          if (res.data.length === 0) {
            toggleTutorial()
          }
        })
        .catch((error) => {
          alert(error.response?.data?.message ?? 'エラー')
          console.log(error)
        })
    }
    // eslint-disable-next-line
  }, [param])

  const sendSMS = () => {
    if (isSubmitting) return
    setIsSubmitting(true)
    if (!(book.dateTime && book.customerName && book.customerNumber && book.customers)) {
      return
    }
    const user = LocalStorageManager.getObjectItem(LocalStorageManager.user)
    const params = {
      ...book,
      price: book.price.replace(/,/g, ''),
      customerNumber: convertPhoneNumber(book.customerNumber),
      createdBy: user._id,
      storeId: storeRef.current._id,
      id: param.id ? decrypt(param.id) : undefined,
    }
    axios
      .post('/api/booking', params)
      .then((res) => {
        const data = res.data.book
        navigate('/accept-booking-complete/' + encrypt(data._id))
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const changePage = () => {
    if (!(book.dateTime && book.customerName && book.customerNumber && book.customers)) {
      alert('内容を入力してください。')
      return
    }
    setPageTitle(preview ? '予約受付' : '予約内容送信プレビュー')
    setPreview(!preview)
  }

  const handleOK = () => {
    setBook({ ...book, paymentMethod: paymentMethod })
    toggle()
  }

  return (
    <div className='default-container bg-default m-auto' style={{ filter: isTutorial && 'blur(3px)' }}>
      <BookingHeader pageTitle={pageTitle} value={-1} setPreview={setPreview} preview={preview} />
      <div className='grid-default-resize'>
        {!preview ? (
          <div>
            <div className='input-box d-flex flex-column booking-price mb-3'>
              <div className='d-flex justify-content-between align-items-center'>
                <span className='color-gray-sub me-2 text16'>
                  <i className='fa-regular fa-clock'></i>
                </span>
                <MobileDateTimePicker
                  ampm={false}
                  closeOnSelect={true}
                  value={dayjs(book.dateTime)}
                  disablePast={true}
                  format={book.dateTime ? `YYYY/MM/DD dd HH:mm` : convertPickerDateTime(new Date())}
                  className={`dt-picker me-2 ${book.dateTime ? '' : 'dt-picker-gray'}`}
                  onChange={(e) => {
                    if (e.toString() === 'Invalid Date') return
                    setBook({ ...book, dateTime: e.toLocaleString() })
                  }}
                />
                {book.dateTime && <i className='fa fa-circle-check check-indicator'></i>}
              </div>
            </div>
            <InputBook
              icon={'fa-regular fa-user'}
              name={'customer-name'}
              placeholder={'お客様の名前'}
              value={book.customerName}
              handleChange={(e) => setBook({ ...book, customerName: e.target.value })}
              checkable={true}
            />
            <InputBook
              isNumber={true}
              icon={'fa fa-users'}
              name={'customers'}
              placeholder={'予約人数'}
              value={book.customers}
              handleChange={(e) => setBook({ ...book, customers: e.target.value })}
              checkable={true}
            />
            <InputBook
              isNumber={true}
              icon={'fa fa-phone'}
              name={'sender-number'}
              placeholder={'送信先の電話番号'}
              value={book.customerNumber}
              handleChange={(e) => setBook({ ...book, customerNumber: e.target.value.replace(/[^0-9-]+/g, '') })}
              checkable={true}
            />
            <InputBook
              isNumber={true}
              icon={'fa fa-yen-sign'}
              name={'price'}
              placeholder={'0000'}
              value={replaceText(book.price)}
              handleChange={(e) => setBook({ ...book, price: e.target.value.replace(/[^0-9]+/g, '') })}
              checkable={true}
            />
            <InputBook
              isTextArea={true}
              icon={'fa fa-pencil'}
              name={'store-address'}
              placeholder={'メッセージ'}
              value={book.product}
              handleChange={(e) => setBook({ ...book, product: e.target.value })}
              checkable={true}
            />
            <InputModalSelect label={'決済方法'} text={METHOD_TEXT[book.paymentMethod - 1]} value={book.paymentMethod} handleClick={toggle} icon={'fa-regular fa-credit-card'} />
          </div>
        ) : (
          <div className='mt-4 d-flex flex-column align-items-end'>
            <CloneButton handleCopy={() => handleCopy(book)} />
            <div className='input-box mt-2 color-gray-font text-start'>
              <div>{book.customerName}様</div>
              <div>
                {storeRef.current.storeName}です。{book.paymentMethod === 3 || book.paymentMethod === null ? 'ご予約ありがとうございます。' : 'ご予約を完了させてください。'}
                <div>{process.env.REACT_APP_AXIOS_BASEURL}</div>
              </div>
            </div>
          </div>
        )}
        <div className='mt-5'></div>
        <CustomButton icon='fa fa-paper-plane' text={'この内容で送る'} disabled={!(book.dateTime && book.customerName && book.customerNumber && book.customers)} handleOnClick={() => sendSMS()} />
        <div className='color-gray-main text-center pt-4 pb-5'>
          <span className='cursor-pointer' onClick={() => changePage()}>
            {preview ? '内容を変更する' : '送信プレビューを確認'}
          </span>
        </div>
      </div>
      <Modal isOpen={isModalOpen} toggle={toggle} className='policy-modal' fade={false}>
        <ModalBody>
          <div className='color-gray-accent text16'>決済方法</div>
          <div className={`policy-card-box mt-2 ${paymentMethod === 1 ? 'bold-border' : 'opacity-60'}`} onClick={() => setPaymentMethod(1)}>
            <div className='policy-card-body cursor-pointer'>
              <div className='h-0' style={{ opacity: '0' }}>
                -----------------------------------------------------------------------------
              </div>
              <div className='color-gray-font '>{METHOD_TEXT[0]}</div>
              <div className='color-gray-main text18 mt-1'>
                <span className='roboto'>100 </span>
                <span className=''>円 / 回</span>
              </div>
              {detail === 1 && (
                <div className=''>
                  <div className='color-gray-sub text-14 mt-2'>予約確定後、カードの有効期限切れになった場合などでも売上の保障が得られます。</div>
                  <div className='color-gray-sub text-14 grid-card-detail mt-3'>
                    <span>・</span>
                    <span>予約金額の変更</span>
                  </div>
                  <div className='color-gray-sub text-14 grid-card-detail mt-2'>
                    <span>・</span>
                    <span>支払い方法の変更（現金払いへの変更）</span>
                  </div>
                  <div className='color-gray-sub text-14 grid-card-detail mt-2'>
                    <span>・</span>
                    <span>電話でのオペレーターサポート</span>
                  </div>
                </div>
              )}
            </div>
            <div
              className='policy-card-footer cursor-pointer h-40  bg-gray-main-heavy'
              onClick={() => {
                setDetail(detail === 1 ? 0 : 1)
              }}
            >
              {detail === 1 ? (
                <>
                  <i className='fa fa-angle-up me-3'></i>
                  <span>閉じる</span>
                </>
              ) : (
                <>
                  <i className='fa fa-angle-down me-3'></i>
                  <span>詳しく見る</span>
                </>
              )}
            </div>
          </div>
          <div className={`policy-card-box mt-3 ${paymentMethod === 2 ? 'bold-border' : 'opacity-60'}`} onClick={() => setPaymentMethod(2)}>
            <div className='policy-card-body cursor-pointer'>
              <div className='color-gray-font '>{METHOD_TEXT[1]}</div>
              <div className='color-gray-main mt-1'>無料</div>
              {detail === 2 && (
                <div className=''>
                  <div className='color-gray-sub text-14 mt-2'>予約確定後、カードの有効期限切れになった場合などに売上が支払われません。</div>
                </div>
              )}
            </div>
            <div className='policy-card-footer cursor-pointer h-40  bg-gray-main-heavy' onClick={() => setDetail(detail === 2 ? 0 : 2)}>
              {detail === 2 ? (
                <>
                  <i className='fa fa-angle-up me-3'></i>
                  <span>閉じる</span>
                </>
              ) : (
                <>
                  <i className='fa fa-angle-down me-3'></i>
                  <span>詳しく見る</span>
                </>
              )}
            </div>
          </div>
          <div className={`policy-card-box mt-3 ${paymentMethod === 3 ? 'bold-border' : 'opacity-60'}`} onClick={() => setPaymentMethod(3)}>
            <div className='policy-card-body cursor-pointer'>
              <div className='color-gray-font '>{METHOD_TEXT[2]}</div>
              <div className='color-gray-sub mt-1'>無料</div>
            </div>
            <div className='policy-card-footer cursor-pointer h-40  bg-gray-main-heavy' onClick={() => setDetail(detail === 3 ? 0 : 3)}>
              {detail === 3 ? (
                <>
                  <i className='fa fa-angle-up me-3'></i>
                  <span>閉じる</span>
                </>
              ) : (
                <>
                  <i className='fa fa-angle-down me-3'></i>
                  <span>詳しく見る</span>
                </>
              )}
            </div>
          </div>
          <div className='mt-4 mb-2'>
            <CustomButton text={'決定'} handleOnClick={() => handleOK()} />
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={isTutorial} toggle={toggleTutorial} className='alert-modal' centered size='sm' fade={false}>
        <ModalBody>
          <div className='py-3 d-flex flex-column align-items-center'>
            <div className='color-gray-main text16 mb-3 text-center'>
              <div>項目を入力するだけで、</div>
              <div>お支払いリンクを自動で作成！</div>
            </div>
            <div className='input-box text-start pane'>
              <div className='color-gray-main text12'>サロン〇〇です。</div>
              <div className='mt-3' style={{ height: '10px', background: '#dadada' }}></div>
              <div className='mt-3' style={{ height: '10px', background: '#dadada' }}></div>
              <div className='mt-3' style={{ height: '10px', background: '#dadada' }}></div>
              <div className='color-gray-main text12 mt-3 mb-3'>以下のリンクから確認してください。</div>
              <div className='mt-1' style={{ color: '#4C85E5' }}>
                https://www.sample.com/
              </div>
            </div>
            <button className='btn-submit color-gray-main' style={{ position: 'absolute', bottom: '-70px', background: '#FFFFFF', fontWeight: 500 }} onClick={() => toggleTutorial()}>
              閉じる
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default AcceptBooking
