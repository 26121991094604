import React, { useState, useEffect } from 'react'
import TopHeader from '../../component/TopHeader'
import { useNavigate } from 'react-router-dom'
import { getParamDueDate } from '../../util/convert'
// import { LocalStorageManager } from '../../util/localStorageManager';
// import axios from 'axios';

const LastHistory = () => {
  const navigate = useNavigate()
  const [transfers, setTransfers] = useState([])
  // const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    // const store = LocalStorageManager.getObjectItem(LocalStorageManager.store);
    // const date = new Date();
    // setEndDate({
    //     year: date.getFullYear(),
    //     month: date.getMonth() + 1
    // })
    // const param = {
    //     storeId: store._id,
    //     year: date.getFullYear(),
    //     month: date.getMonth() + 1
    // }
    const res = [
      {
        amount: 0,
        chargeIds: [],
        id: 'tr_8f0c0fe2c9f8a47f9d18f03959ba1',
        schedule_date: '2024-06-30',
        transfer_date: null,
      },
      {
        amount: 0,
        chargeIds: [],
        id: 'tr_8f0c0fe2c9f8a47f9d18f03959ba1',
        schedule_date: '2024-05-31',
        transfer_date: '2024-05-31',
      },
    ]

    setTransfers(groupByYear(res))
    // axios.post('/api/getTransfers', param)
    //     .then((res) => {
    //         setTransfers(res.data.transfers);
    //     }).catch((error) => {
    //         alert(error.response?.data?.message ?? "エラー");
    //         console.log(error)
    //     })
  }, [])

  const groupByYear = (transactions) => {
    return transactions.reduce((result, transaction) => {
      const year = new Date(transaction.schedule_date).getFullYear()
      if (!result[year]) {
        result[year] = []
      }
      result[year].push(transaction)
      return result
    }, {})
  }

  const yearTotalCount = (key) => {
    return transfers[key].reduce((total, transfer) => total + transfer.amount, 0)
  }

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'過去の振り込み額'} value={'/transfer'} />
      <div className='grid-default-resize pb-5'>
        {transfers &&
          Object.keys(transfers)
            .sort((a, b) => b - a)
            .map((key, index) => {
              return (
                <div key={index}>
                  <div className='mt-4'>
                    <div className='d-flex justify-content-between'>
                      <span className='ms-2 color-gray-main '>{key}年</span>
                      <div>
                        <span className='color-gray-main  me-2'>合計</span>
                        <i className='fa fa-yen color-gray-font '></i>
                        <span className='roboto text18 me-2'>{yearTotalCount(key)}</span>
                      </div>
                    </div>
                    {transfers[key].map((item) => {
                      return (
                        <div className='input-box d-flex justify-content-between mt-3 cursor-pointer' onClick={() => navigate('/transfer-detail/' + item.schedule_date)} key={item.id}>
                          <div className='color-gray-font py-1 text-ellipsis'>{getParamDueDate(3, item.schedule_date)}月</div>
                          <div className='color-gray-font py-1'>
                            <i className='fa fa-yen me-1'></i>
                            <span className='roboto text18'>{item.amount}</span>
                            <i className='ms-2 fa fa-angle-right'></i>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
      </div>
    </div>
  )
}

export default LastHistory
