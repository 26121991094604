const InputPhoneNumber = ({ phoneNumber, handleChange }) => {
  return (
    <div className='input-box d-flex flex-column mt-3'>
      <div className='color-gray-accent ms-1 mb-1'>電話番号</div>
      <input inputMode='numeric' className='custom-input roboto' name='phone-number' placeholder='09012345678' value={phoneNumber} onChange={handleChange} />
    </div>
  )
}

export default InputPhoneNumber
