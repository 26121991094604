import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { convertPhoneNumber } from '../util/convert'
import TopHeader from '../component/TopHeader'
import { LocalStorageManager } from '../util/localStorageManager'
import CustomButton from '../component/CustomButton'
import InputPhoneNumber from '../component/inputs/InputPhoneNumber'
import InputPassword from '../component/inputs/InputPassword'
import InputNumber from '../component/inputs/InputNumber'

const Login = () => {
  const navigate = useNavigate()
  const [isShow, setIsShow] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState(null)
  const [key, setKey] = useState(null)

  useEffect(() => {
    LocalStorageManager.removeItem(LocalStorageManager.user)
    LocalStorageManager.removeItem(LocalStorageManager.store)
    LocalStorageManager.removeItem(LocalStorageManager.token)
  }, [])

  const userLogin = () => {
    if (phoneNumber === '' || password === null || key === null) {
      return
    }
    const params = {
      phoneNumber: convertPhoneNumber(phoneNumber),
      password: password,
      key: key,
    }
    axios
      .post('/api/login', params)
      .then((res) => {
        console.log(res)
        LocalStorageManager.setObjectItem(LocalStorageManager.user, res.data.user)
        LocalStorageManager.setItem(LocalStorageManager.token, res.data.token)
        if (res.data && res.data.store) {
          LocalStorageManager.setObjectItem(LocalStorageManager.store, res.data.store)
          navigate('/home')
          return
        }
        navigate('/store-register-first')
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })
  }

  const handlePasswordShow = () => {
    if (password) {
      setIsShow(!isShow)
    }
  }

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'ログイン'} value={'/'} />
      <div className='grid-default'>
        <div className='mb-5'>
          <InputPhoneNumber handleChange={(e) => setPhoneNumber(e.target.value.replace(/[^0-9-]+/g, ''))} />
          <InputPassword isShow={isShow} handlePasswordShow={handlePasswordShow} password={password} handleChange={(e) => setPassword(e.target.value)} />
          <InputNumber label={'認証キー'} name={'key'} placeholder={'000000'} value={key} handleChange={(e) => setKey(e.target.value)} />
        </div>
        <CustomButton text={'ログイン'} handleOnClick={() => userLogin()} disabled={!(password && phoneNumber && key)} />
        <div className='text-center  color-gray-main mt-4'>
          <span className='cursor-pointer' onClick={() => navigate('/change-password')}>
            パスワードをお忘れですか？
          </span>
        </div>
      </div>
    </div>
  )
}

export default Login
