const CustomItem = ({ text, icon, handleClick }) => {
  return (
    <div className='input-box mt-3 cursor-pointer' onClick={handleClick}>
      <div className='ms-1 py-2'>
        <span className='color-gray-main text16'>
          <i className={icon}></i>
        </span>
        <span className='ms-3 color-gray-font'>{text}</span>
      </div>
    </div>
  )
}

export default CustomItem
