import React from 'react'
import Button from '@mui/material/Button'

const CustomButton = ({ icon, text, handleOnClick, color, sm, disabled, plain }) => {
  return (
    <Button
      variant={plain ? 'outlined' : 'contained'}
      className={sm ? 'btn-submit-sm' : 'btn-submit'}
      color={color ? color : 'primary'}
      sx={{
        borderRadius: '24px',
        fontWeight: 'bold',
        fontSize: '16px',
        '&.Mui-disabled': {
          color: disabled ? '#FFFFFF' : undefined,
          backgroundColor: disabled ? '#BCBBB9' : undefined,
        },
      }}
      disabled={disabled}
      onClick={() => handleOnClick()}
    >
      {icon && <i className={`${icon} me-3`}></i>}
      {text}
    </Button>
  )
}

export default CustomButton
