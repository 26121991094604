import React from 'react'
import { useNavigate } from 'react-router-dom'
import salon from '../assets/salon.svg'
import CustomButton from '../component/CustomButton'

const Start = () => {
  const navigate = useNavigate()

  return (
    <div className='start-container'>
      <div className='grid-start bg-start'>
        <div className='m-auto d-grid h-100 align-items-end'>
          <img src={salon} alt='conectic' />
        </div>
        <div className='px-5'>
          <CustomButton text={'ログイン'} handleOnClick={() => navigate('/login')} />
          <div className='text-center color-gray-main  cursor-pointer my-4' onClick={() => navigate('/register')}>
            新規登録
          </div>
        </div>
      </div>
    </div>
  )
}

export default Start
