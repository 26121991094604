import React, { useState } from 'react'
import InputText from './inputs/InputText'
import InputNumber from './inputs/InputNumber'
import InputModalSelect from './inputs/InputModalSelect'
import PostCodeSearchModal from './modals/PostCodeSearchModal'

const RegistrantInputComponent = ({ store, setStore }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const handleSave = (param) => {
    setStore({ ...store, registrantAddress: param })
    toggle()
  }
  return (
    <>
      <InputText
        label={'登録者名'}
        name={'registrant-name'}
        placeholder={'山田 花子'}
        value={store.registrantName}
        handleChange={(e) => setStore({ ...store, registrantName: e.target.value })}
        checkable={true}
      />
      <InputNumber
        label={'電話番号'}
        name={'registrant-number'}
        placeholder={'09012345678'}
        value={store.registrantNumber}
        handleChange={(e) => setStore({ ...store, registrantNumber: e.target.value.replace(/[^0-9-]+/g, '') })}
        checkable={true}
      />
      <InputModalSelect label={'住所'} value={store.registrantAddress} handleClick={toggle} />
      <PostCodeSearchModal isOpen={isOpen} value={store.registrantAddress} toggle={toggle} handleSave={handleSave} />
    </>
  )
}

export default RegistrantInputComponent
