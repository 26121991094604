import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { convertPhoneNumber } from '../../util/convert'
import TopHeader from '../../component/TopHeader'
import CustomButton from '../../component/CustomButton'
import InputPhoneNumber from '../../component/inputs/InputPhoneNumber'

const ChangePassword = () => {
  const navigate = useNavigate()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const handleSubmit = () => {
    if (phoneNumber === '') {
      return
    }
    const params = {
      phoneNumber: convertPhoneNumber(phoneNumber),
    }
    axios
      .post('/api/changePasswordRequest', params)
      .then((res) => {
        setIsSuccess(true)
        console.log(res)
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          setIsError(true)
        } else {
          alert('エラー')
        }
        console.log(error)
      })
  }

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'パスワードのリセット'} value={'/login'} />
      <div className='grid-default'>
        {!isSuccess ? (
          <div>
            <div className='mb-5'>
              <div className='text-medium color-gray-font my-3'>ご登録いただいている電話番号宛に、パスワードをリセットするためのリンクをお送りします。</div>
              <InputPhoneNumber handleChange={(e) => setPhoneNumber(e.target.value.replace(/[^0-9-]+/g, ''))} />
              <div className={`color-red ${isError ? 'mt-1 text-medium' : 'd-none'}`}>この電話番号は登録されていません。</div>
            </div>
            <CustomButton text={'リンクを送信する'} handleOnClick={() => handleSubmit()} disabled={phoneNumber ? false : true} />
          </div>
        ) : (
          <div>
            <div className='color-gray-accent text-center text24 roboto-black mt-4'>{phoneNumber.replace('+81', '0').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}</div>
            <div className='text-center text-medium color-gray-font mt-2'>にSMSメッセージを送信しました。</div>
            <div className='color-gray-font text-medium m-3'>メッセージに記載されているリンクからパスワードをリセットしてください。</div>
            <div className='mt-4'></div>
            <CustomButton text={'ログインに戻る'} handleOnClick={() => navigate('/login')} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ChangePassword
